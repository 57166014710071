import Keys from '../shared/pubsub-keys'
import PubSub from 'pubsub-js'

let searchExpand, searchField, search
const ACTIVE_CLASS = 'search-active'
const SEARCH_RESULT_URL = '/search_result/?keywords'

function init () {
  search = document.querySelector('.js-search-form')
  searchField = search.querySelector('.js-search-field')
  searchExpand = search.querySelector('.js-search-expand')

  searchExpand.addEventListener('click', e => {
    e.preventDefault()
    if (document.body.classList.contains(ACTIVE_CLASS)) {
      hideSearch()
      return
    }
    activateSearch()
  })

  searchField.addEventListener('input', searchListener)
  searchField.addEventListener('keydown', keyListener)

  PubSub.subscribe(Keys.MENU_OPEN, hideSearch)
}

const keyListener = ({keyCode, target}) => {
  if (keyCode === 13) {
    window.location = `${SEARCH_RESULT_URL}=${target.value}`
  }
}

function searchListener (event) {
  PubSub.publish(Keys.SEARCH_SUGGEST, event.target.value)
}

function activateSearch () {
  document.body.classList.add(ACTIVE_CLASS)
  PubSub.publish(Keys.SEARCH_ACTIVE)
  searchField.focus()
}

function hideSearch () {
  document.body.classList.remove(ACTIVE_CLASS)
}

export default init
