const TRACKING_CLASSES = {
  CLICK: 'js-click-track'
};

function setupGtmClickTracking() {
  document.addEventListener("click", e => {
    const element = e.target;
    if (!element.classList.contains(TRACKING_CLASSES.CLICK)) return;

    const tracking = formatTrackingString(element.dataset.tracking);
    if (!tracking) return;

    const trackingAsArray = Array.isArray(tracking) ? tracking : [tracking];
    trackingAsArray.forEach(track => window.dataLayer.push(track));
  });
}

function formatTrackingString(trackingString) {
  if (!trackingString) return null;

  return JSON.parse(trackingString.replace(/'/g, '"'));
}

function pushGtmEvent(eventLabel) {
  window.dataLayer.push({
    event: eventLabel
  });
}

function pushGtmObjectEvent(event, label, action, value = null) {
  window.dataLayer.push({
    event: event,
    label: label,
    action: action,
    value: value
  });
}

//Specific Google Analytics event to log a products stock status. Used on SRP and VIP add to basket and product clicks
function productStockStatusEvent(stockStatusEvent, stockStatusNpkId, stockStatusAction, stockStatusValue) {
  if(typeof stockStatusValue == 'string') {
    stockStatusValue = parseInt(stockStatusValue)
  }
  window.dataLayer.push({
    event: stockStatusEvent,
    stockStatusNpkId,
    stockStatusAction,
    stockStatusValue,
  });
}

function pushGtmObjectEventWithProduct(product, event, label, action ) {
  const discount = Math.max(0, product.priceBefore - product.price) || 0;
  window.dataLayer.push({
    event: "add_to_cart",
    ecommerce: {
      currency: window.currency,
      value: product.price,
      items: [{
        item_id: product.npkId,
        item_name: product.name,
        currency: window.currency,
        quantity: 1,
        discount,
        price: product.price + discount,
        item_brand: product.brand,
        product_category: product.categoryName,
        in_stock: product.isInStock ? 1 : 0,
      }],
    }
  });
  window.dataLayer.push({
    event: event,
    label: label,
    action: action,
    product_id: product.npkId,
    product_name: product.name,
    product_category: product.categoryName,
    product_category_singular: product.categoryNameSingular,
    product_rating: product.ratingsAverage,
    brand: product.brand,
    image_url: product.imgPath,
    product_price: product.price,
    price_before: product.priceBefore,
    savings_percentage: product.discountPercentage,
    energy_label: product.energyLabel,
    campaign_banner_extra_info: product.campaignDealData.campaignExpiresText,
    product_is_in_stock: product.isInStock
  });
}

function pushGtmEventWithValue(event, category, label, action, value) {
  if(typeof value == 'string') {
    value = parseInt(value)
  }
  window.dataLayer.push({
    event: event,
    category: category,
    label: label,
    action: action,
    value: value,
  });
}

export {
  TRACKING_CLASSES,
  setupGtmClickTracking,
  pushGtmEvent,
  pushGtmObjectEvent,
  productStockStatusEvent,
  pushGtmObjectEventWithProduct,
  pushGtmEventWithValue
};
